import { Component } from '@angular/core';
import { debounceTime } from 'rxjs';
import { FieldsService } from 'src/app/services/fields.service';
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';

@Component({
  selector: 'app-area-distribution-chart',
  templateUrl: './area-distribution-chart.component.html',
  styleUrls: ['./area-distribution-chart.component.scss']
})
export class AreaDistributionChartComponent {


  options: any = this.getOptions([]);


  constructor(private fs: FilteredFieldsService) {
    fs.filteredAreas$.pipe(debounceTime(300)).subscribe((areas)=> {
      this.options = this.getOptions(areas);
    });
  }

  getOptions(data: number[]) {
    const bucketSize = 1000;

    const max = Math.max(...data);
    const xAxisData = Array.from({ length: Math.floor(max / bucketSize) + 1 }, (_, i) => '' + (i * bucketSize) + '-' + ((i + 1) * bucketSize) + 'm²');
    const data1 = Array.from({ length: Math.floor(max / bucketSize) + 1 }, (_, __) => 0);
    for (let i = 0; i < data.length; i++) {
      const bucket = Math.floor(data[i] / bucketSize);
      data1[bucket] += 1;
    }



    return {
      tooltip: {},
      xAxis: {
        data: xAxisData,
        type: 'category',
      },
      yAxis: {
        type: 'value',

      },
      series: [
        {
          name: 'Area in m²',
          type: 'bar',
          data: data1,
        },
      ]
    };
  }
}
