<div style="height: 100%; display:flex; flex-direction: column;">
  <div style="display: flex; flex-direction: row">
    <button mat-icon-button aria-label="" style="flex-grow: 0" (click)="onClose()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <app-info-section style="flex-grow: 1"></app-info-section>
  </div>
  <mat-accordion displayMode="flat" [multi]="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Land Use
        </mat-panel-title>
        <mat-panel-description>
          Pie Chart displaying the ratio of used vs unused land
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-land-use></app-land-use>
    </mat-expansion-panel >
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Distribution of field Areas
        </mat-panel-title>
        <mat-panel-description>
          Histogram of the distribution of field areas
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-area-distribution-chart></app-area-distribution-chart>
    </mat-expansion-panel >
    <mat-expansion-panel >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Field Use areas
        </mat-panel-title>
        <mat-panel-description>
          Bar Chart comparing total area of each field use
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-field-use-area-chart></app-field-use-area-chart>
    </mat-expansion-panel>
    <mat-expansion-panel  >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Area to Field count
        </mat-panel-title>
        <mat-panel-description>
          Bar chart comparing the number of fields to the total area of each field use
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-area-to-count-chart></app-area-to-count-chart>
    </mat-expansion-panel>
  </mat-accordion>
</div>

