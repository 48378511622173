import { Component, OnInit } from '@angular/core';
import { combineLatest, firstValueFrom } from 'rxjs';
import { FieldUse, FieldUseControllerService } from 'src/app/api';
import { FieldUseService } from 'src/app/services/field-use.service';
import { FilterService } from 'src/app/services/filter.service';
import RandomColor from 'src/app/util/random_color';

@Component({
  selector: 'app-filter-area-card',
  templateUrl: './filter-area-card.component.html',
  styleUrls: ['./filter-area-card.component.scss']
})
export class FilterAreaCardComponent  {
  fieldUses: [FieldUse, number, boolean][] = [];
  constructor(public fs: FieldUseService, public fil: FilterService) {
    combineLatest([this.fs.visibleFieldUses$, this.fil.enabledFieldUses$]).subscribe(([fieldUses, enabledFieldUses]) => {
      this.fieldUses = fieldUses.map(([fieldUse, count]) => {
        const enabled = enabledFieldUses.find((f) => f[0].id == fieldUse.id);
        return [fieldUse, count, enabled ? enabled[1] : false];
      });
      this.fieldUses.sort((a, b) => b[1] - a[1]);
    });
  }

  truncate(str: string, n: number){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };

  sortFieldUses(fieldUses: [FieldUse, number][], ascending = false): [FieldUse, number][] {
    if (ascending) {
      return fieldUses.sort((a, b) => a[1] - b[1]);
    }
    return fieldUses.sort((a, b) => b[1] - a[1]);
  }

  getColor(fieldUse: FieldUse): string {
    return RandomColor.getRandomColorBasedOnNumber(parseInt(fieldUse.snarId));
  }

  get anyDisabled(): boolean {
    if(this.fieldUses.length == 0) return false;
    return this.fieldUses.some((f) => !f[2]);
  }

  async toggleAll() {
    if (this.anyDisabled) {
      await this.fil.enableAllFieldUses();
    } else {
      await this.fil.disableAllFieldUses();
    }
  }
}
