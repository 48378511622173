import { Component } from '@angular/core';
import { combineLatest, debounceTime, UnsubscriptionError } from 'rxjs';
import { Field } from 'src/app/api';
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';
import { VisibleAreaService } from 'src/app/services/visible-area.service';
import GisUtils from 'src/app/util/gis-utils';

@Component({
  selector: 'app-land-use',
  templateUrl: './land-use.component.html',
  styleUrls: ['./land-use.component.scss']
})
export class LandUseComponent {

  options: any;


  constructor(private fs: FilteredFieldsService, private va: VisibleAreaService) {
    combineLatest([fs.filteredFields$, va.boundingBox$]).pipe(debounceTime(300)).subscribe(([fields, boundingBox]) => {
      this.options = this.getOptions(fields, boundingBox);
    });
  }

  getOptions(fields: Field[], boundingBox: google.maps.LatLngBoundsLiteral) {
    const usedLand = Math.round((fields.map(f => f.area!).reduce((a, b) => a + b, 0)/10000)*100)/100;
    let totalArea = GisUtils.calculatePolygonArea(
      [[boundingBox.north, boundingBox.west], [boundingBox.north, boundingBox.east], [boundingBox.south, boundingBox.east], [boundingBox.south, boundingBox.west], [boundingBox.north, boundingBox.west]]
    );
    totalArea = Math.round((totalArea/10000)*100)/100; // ha conversion
    console.log("totalArea", totalArea, "usedLand", usedLand, "unusedLand", (totalArea - usedLand))
    const unusedLand = Math.round((totalArea - usedLand)*100)/100;

    // get image of repeating trees
    const piePatternSrc = 'https://static.vecteezy.com/system/resources/previews/007/984/827/original/ground-seamless-texture-game-ui-for-the-game-farm-brown-background-of-cultivated-land-vector.jpg';
    const forestPatternSrc = 'https://media.istockphoto.com/id/639829018/vector/seamless-forest-pattern.jpg?s=612x612&w=0&k=20&c=lWX08Y0NWzb2zjJQzOPnJOtcDwrpldq5LGg3nIEkGbI=';
    const forestPatternImg = new Image();
    forestPatternImg.src = forestPatternSrc;

    const piePatternImg = new Image();
    piePatternImg.src = piePatternSrc;

    return {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ha ({d}%)'
      },
      series: [
        {
          type: 'pie',
          selectedMode: 'single',
          selectedOffset: 30,
          clockwise: true,
          label: {
            fontSize: 18,
            fontWeight: 'bold',
            color: 'white'
          },
          data: [
            usedLand > 0?{
              value: usedLand, name: 'Used Land', itemStyle: {
                color: {
                  image: piePatternImg,
                  repeat: 'repeat'
                },
              },
            }:null,
            unusedLand > 0?{
              value: unusedLand, name: 'Unused land', itemStyle: {
                color: {
                  image: forestPatternImg,
                  repeat: 'repeat'
                }
              }
            }:null,
          ],
        }
      ]
    };
  }
}
