<mat-card class="filter-card">
  <mat-card-content style="overflow-y:auto;">
    <div style="display: flex; flex-direction: row; align-items: center;">
      <span style="margin-right: 16px;flex-grow: 1">Filter by Area use type</span>
      <button mat-stroked-button (click)="toggleAll()">
        <mat-icon *ngIf="!anyDisabled">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="anyDisabled">check_box</mat-icon>
        {{ anyDisabled ? 'Select all':'Deselect all' }}
      </button>
    </div>

    <section style="display:flex; flex-direction: column;">
      <div *ngFor="let f of fieldUses" style="display:flex; flex-direction: row;align-items: center;justify-content: space-between;" class="light-on-hover">
        <mat-checkbox [checked]="f[2]" (change)="fil.toggleFieldUse(f[0])" class="example-margin" matTooltip="{{f[0].name}}" style="flex-grow:1">{{truncate(f[0].name, 15)}}</mat-checkbox>
        <span style="margin-left: 8px;margin-right: 8px;">{{f[1]}}</span>
        <div style="height: 16px; width: 16px; border-radius: 4px;" [style.background-color]="getColor(f[0])" ></div>
      </div>
    </section>
  </mat-card-content>
</mat-card>
