export default class GisUtils {
  static getCenterOfPolygon(polygon: number[][]): google.maps.LatLngLiteral {
    const bounds = new google.maps.LatLngBounds();
    polygon.forEach((coordinate) => {
      bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
    });
    return bounds.getCenter().toJSON();
  }

  static calculatePolygonArea(coordinates: number[][]) {
    // Convert LatLng coordinates to Cartesian coordinates in meters
    const earthRadius = 6378137; // Earth's radius in meters
    const metersPerDegree = 2 * Math.PI * earthRadius / 360;
    const cartesianCoordinates = coordinates.map(coord => {
      const x = coord[0] * metersPerDegree * Math.cos(coord[1] * Math.PI / 180);
      const y = coord[1] * metersPerDegree;
      return { x, y };
    });

    // Calculate the area of the polygon using the Shoelace formula
    let sum = 0;
    for (let i = 0; i < cartesianCoordinates.length; i++) {
      const j = (i + 1) % cartesianCoordinates.length;
      sum += cartesianCoordinates[i].x * cartesianCoordinates[j].y - cartesianCoordinates[j].x * cartesianCoordinates[i].y;
    }
    const area = Math.abs(sum) / 2;

    return area;
  }

  static simplifyPolyline(polyline: number[][], tolerance: number): number[][] {
    // Check if the polyline has enough points to simplify
    if (polyline.length <= 2) {
      return polyline;
    }

    // Find the point with the maximum distance from the line segment
    var maxDistance = 0;
    var index = 0;
    for (var i = 1; i < polyline.length - 1; i++) {
      var distance = this.perpendicularDistance(polyline[i], polyline[0], polyline[polyline.length - 1]);
      if (distance > maxDistance) {
        maxDistance = distance;
        index = i;
      }
    }

    // If the maximum distance is greater than the tolerance, recursively simplify
    if (maxDistance > tolerance) {
      var left = polyline.slice(0, index + 1);
      var right = polyline.slice(index);
      var simplifiedLeft = this.simplifyPolyline(left, tolerance);
      var simplifiedRight = this.simplifyPolyline(right, tolerance);
      return simplifiedLeft.slice(0, simplifiedLeft.length - 1).concat(simplifiedRight);
    } else {
      return [polyline[0], polyline[polyline.length - 1]];
    }
  }

  // Calculate the perpendicular distance between a point and a line segment
  static perpendicularDistance(point: number[], start:number[], end:number[]) {
    var dx = end[0] - start[0]; // lng
    var dy = end[1] - start[1]; // lat
    var lengthSquared = dx * dx + dy * dy;
    var u = ((point[0] - start[0]) * dx + (point[1] - start[1]) * dy) / lengthSquared;
    var x, y;
    if (u < 0) {
      x = start[0];
      y = start[1];
    } else if (u > 1) {
      x = end[0];
      y = end[1];
    } else {
      x = start[0] + u * dx;
      y = start[1] + u * dy;
    }
    var dx2 = point[0] - x;
    var dy2 = point[1] - y;
    return Math.sqrt(dx2 * dx2 + dy2 * dy2);
  }

}
