<mat-drawer-container class="example-container" [hasBackdrop]="false">
  <mat-drawer #drawer mode="over" position="end" opened="true" class="sidenav-drawer" autosize>
    <app-sidebar (close)="drawer.close()"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content class="fullscreen-background">
    <app-map [showFields]="showFields"></app-map>
    <div style="position: absolute;top: 16px; left: 16px;height: calc(100vh - 32px);display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row; align-items: center; flex-grow:0">
        <app-search-box>
        </app-search-box>
        <div style="display: flex; flex-direction: column;margin-left: 16px;">
          <button mat-fab color="accent" (click)="toggleShowFields()">
            <mat-icon *ngIf="showFields" fontIcon="layers"></mat-icon>
            <mat-icon *ngIf="!showFields" fontIcon="layers_clear"></mat-icon>
          </button>
          <button mat-fab color="accent" style="margin-top: 16px" (click)="toggleShowFilters()">
            <mat-icon *ngIf="showFilters" fontIcon="filter_alt"></mat-icon>
            <mat-icon *ngIf="!showFilters" fontIcon="filter_alt_off"></mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="showFilters" style="margin-top: 16px;flex-grow:0">
        <app-filter-card></app-filter-card>
      </div>
      <div *ngIf="showFilters" style="margin-top: 16px;flex-grow:1;">
        <app-filter-area-card></app-filter-area-card>
      </div>
    </div>
    <div style="position: absolute; top: 16px; right: 16px; display: flex; flex-direction: row; align-items: center;">
      <button mat-fab color="accent" (click)="drawer.open()">
        <mat-icon fontIcon="analytics"></mat-icon>
      </button>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
