import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FieldUse, FieldUseControllerService } from '../api';
import { FieldsService } from './fields.service';

@Injectable({
  providedIn: 'root'
})
export class FieldUseService {
  private fieldUses = new ReplaySubject<FieldUse[]>();
  fieldUses$ = this.fieldUses.asObservable();

  private visibleFieldUses = new ReplaySubject<[FieldUse, number][]>();
  visibleFieldUses$ = this.visibleFieldUses.asObservable();

  constructor(private fuc: FieldUseControllerService, private fs: FieldsService) {
    this.fuc.getAll().subscribe((fieldUses) => {
      this.fieldUses.next(fieldUses);
    });

    this.fs.fields$.subscribe((fields) => {
      const fieldUses = fields.map((field) => field.fieldUse!);

      // get unique field uses and count them
      const uniqueFieldUses:[FieldUse, number][] = fieldUses.filter((fieldUse, index, self) => {
        return self.findIndex((f) => f.id == fieldUse.id) == index;
      }).map((fieldUse) => {
        return [fieldUse, fieldUses.filter((f) => f.id == fieldUse.id).length];
      })

      this.visibleFieldUses.next(uniqueFieldUses);
    });
  }
}
