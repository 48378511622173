<div class="">
  <div class="flex-row outer">
    <div class="flex-row">
      <p>{{(fs.filteredFields$ | async)?.length}}</p><span>Fields</span>
    </div>
    <div class="flex-row">
      <p>{{(fus.visibleFieldUses$ | async)?.length}}</p><span>Field types</span>
    </div>
  </div>

  <div class="flex-row outer">
    <div class="flex-row">
      <p>{{area}}</p><span>Total area in m²</span>
    </div>
  </div>
</div>
