import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, map, Observable } from 'rxjs';
import { BoundingBoxDto } from '../api';

@Injectable({
  providedIn: 'root'
})
export class VisibleAreaService {
  private static initialBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(48.3323554940344, 14.30568120686932),
    new google.maps.LatLng(48.34172014856629, 14.331576785464591)
  );
  private boundingBox = new BehaviorSubject<google.maps.LatLngBounds>(VisibleAreaService.initialBounds);

  public boundingBox$: Observable<BoundingBoxDto> = this.boundingBox.pipe(
    debounceTime(200),
    map<google.maps.LatLngBounds, BoundingBoxDto>((bounds) => {
      return {
        north: bounds.getNorthEast().lat(),
        east: bounds.getNorthEast().lng(),
        south: bounds.getSouthWest().lat(),
        west: bounds.getSouthWest().lng(),
      };
    })
  );

  constructor() { }

  public setBounds(bounds: google.maps.LatLngBounds) {
    this.boundingBox.next(bounds);
  }
}
