import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAccordionDisplayMode } from '@angular/material/expansion';

import { ApiModule, Configuration, ConfigurationParameters } from './api/';
import { NgxEchartsModule } from "ngx-echarts";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './ui/sidebar/sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchBoxComponent } from './ui/search-box/search-box.component';
import { LineBarChartComponent } from './charts/line-bar-chart/line-bar-chart.component';
import { MapComponent } from './ui/map/map.component';
import { AreaDistributionChartComponent } from './charts/area-distribution-chart/area-distribution-chart.component';
import { FieldUseAreaChartComponent } from './charts/field-use-area-chart/field-use-area-chart.component';
import { FilterCardComponent } from './ui/filter-card/filter-card.component';
import { FilterAreaCardComponent } from './ui/filter-area-card/filter-area-card.component';
import { InfoSectionComponent } from './ui/info-section/info-section.component';
import { AreaToCountChartComponent } from './charts/area-to-count-chart/area-to-count-chart.component';
import { LandUseComponent } from './charts/land-use/land-use.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: 'https://map-api.vinitor.app'
  }
  return new Configuration(params);
}


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SearchBoxComponent,
    LineBarChartComponent,
    MapComponent,
    AreaDistributionChartComponent,
    FieldUseAreaChartComponent,
    FilterCardComponent,
    FilterAreaCardComponent,
    InfoSectionComponent,
    AreaToCountChartComponent,
    LandUseComponent
  ],
  imports: [
    BrowserModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    AppRoutingModule,
    GoogleMapsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
