import { Field } from "../api";
import GisUtils from "./gis-utils";

export default class GoogleMapsUtils {
  static cachedVertices: { [key: string]: google.maps.LatLngLiteral[] } = {};

  static fieldToVertices(field: Field): google.maps.LatLngLiteral[] {
    let coordinates = field.polygon.coordinates![0] as Array<number[]>;
    if(field.id! in this.cachedVertices) {
      return this.cachedVertices[field.id!];
    }

    let simplifiedPaths = [];
    for (var i = 0; i < coordinates.length-1; i++) {
      var point = coordinates[i];
      simplifiedPaths.push(point);
    }
    let simplified = GisUtils.simplifyPolyline(simplifiedPaths, 0.00001);
    simplified.push(simplified[0]);
    const latLngList: Array<google.maps.LatLngLiteral> = simplified.map((coordinate) => ({
      lat: coordinate[1],
      lng: coordinate[0],
    }));

    this.cachedVertices[field.id!] = latLngList;

    return latLngList;
  }

  static fieldToBox(field: Field): google.maps.LatLngBounds {
    const coordinates = field.polygon.coordinates![0] as Array<number[]>;
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((coordinate) => {
      bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
    });

    return bounds;
  }

  static boxToVertices(bounds: google.maps.LatLngBounds): google.maps.LatLngLiteral[] {
    const southEast = { lat: bounds.getSouthWest().lat(), lng: bounds.getNorthEast().lng() };
    const northWest = { lat: bounds.getNorthEast().lat(), lng: bounds.getSouthWest().lng() };
    const latLngList: Array<google.maps.LatLngLiteral> = [
      bounds.getNorthEast().toJSON(),
      southEast,
      bounds.getSouthWest().toJSON(),
      northWest,
    ];

    return latLngList;
  }
}
