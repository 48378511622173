import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { FieldUse, FieldUseControllerService } from '../api';
import { FieldUseService } from './field-use.service';
import { FieldsService } from './fields.service';

export interface AreaRange {
  min?: number;
  max?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private areaRange = new BehaviorSubject<AreaRange>({});
  private enabledFieldUses = new ReplaySubject<[FieldUse, boolean][]>();

  areaRange$ = this.areaRange.asObservable();
  enabledFieldUses$ = this.enabledFieldUses.asObservable();

  constructor(private fuc: FieldUseControllerService, private fs: FieldsService) {
    this.fuc.getAll().subscribe((fieldUses) => {
      this.enabledFieldUses.next(fieldUses.map((fieldUse) => [fieldUse, true]));
    });
  }

  async setMinArea(area: number) {
    const range = await firstValueFrom(this.areaRange$);
    range.min = area;
    this.areaRange.next(range);
  }

  async setMaxArea(area: number) {
    const range = await firstValueFrom(this.areaRange$);
    range.max = area;
    this.areaRange.next(range);
  }


  async toggleFieldUse(fieldUse: FieldUse) {
    const enabled = await firstValueFrom(this.enabledFieldUses$);
    const index = enabled.findIndex((f) => f[0].id == fieldUse.id);
    enabled[index][1] = !enabled[index][1];
    this.enabledFieldUses.next(enabled);
  }

  async enableAllFieldUses() {
    const enabled = await firstValueFrom(this.enabledFieldUses$);
    enabled.forEach((f) => f[1] = true);
    this.enabledFieldUses.next(enabled);
  }

  async disableAllFieldUses() {
    const enabled = await firstValueFrom(this.enabledFieldUses$);
    enabled.forEach((f) => f[1] = false);
    this.enabledFieldUses.next(enabled);
  }
}
