import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { VisibleAreaService } from '../../services/visible-area.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements AfterViewInit{
  @ViewChild('search')
  public searchElementRef!: ElementRef<HTMLInputElement>;

  place: google.maps.places.PlaceResult | undefined;

  constructor(private ngZone: NgZone, private va: VisibleAreaService, private ss: SearchService) {}

  ngAfterViewInit(): void {
    // Binding autocomplete to search input control
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.onSearchChange(place);
      });
    });
  }

  removePlace() {
    this.searchElementRef.nativeElement.value = "";
    this.place = undefined;
  }

  onSearchChange(place: google.maps.places.PlaceResult) {
    //verify result
    if (place.geometry === undefined || place.geometry === null) {
      return;
    }

    if (place.geometry.location === undefined || place.geometry.location === null) {
      return;
    }

    console.log({ place }, place.geometry.location?.lat());
    this.place = place;

    //set latitude, longitude and zoom
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    this.ss.setLocation(new google.maps.LatLng(latitude, longitude));
  }
}
