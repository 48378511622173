<mat-card class="search-card">
  <mat-card-header>
    <mat-card-title style="color: white; display: flex; align-items: center;">
      <ng-container *ngIf="place == null; else placeHeader">
        <mat-icon>search</mat-icon>
        Search
      </ng-container>
      <ng-template #placeHeader>
        <mat-icon>place</mat-icon>
        <span style="margin-left: 12px">{{place?.name}}</span>
        <button mat-icon-button color="secondary" aria-label="Icon button with a close icon" (click)="removePlace()">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field [class.hidden]="place != null" class="full-width">
      <mat-label>Some place</mat-label>
      <input matInput type="text" class="my-search-box" (keydown.enter)="$event.preventDefault()"
        placeholder="Search Location" #search />
    </mat-form-field>
  </mat-card-content>
</mat-card>
