import { Component } from '@angular/core';
import { debounceTime } from 'rxjs';
import { Field } from 'src/app/api';
import { FieldsService } from 'src/app/services/fields.service';
import GisUtils from 'src/app/util/gis-utils';
import RandomColor from 'src/app/util/random_color';
import { uniqBy } from "lodash"
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';

@Component({
  selector: 'app-field-use-area-chart',
  templateUrl: './field-use-area-chart.component.html',
  styleUrls: ['./field-use-area-chart.component.scss']
})
export class FieldUseAreaChartComponent {


  options: any = this.getOptions([]);


  constructor(private fs: FilteredFieldsService) {
    fs.filteredFields$.pipe(debounceTime(300)).subscribe((fields) => {
      this.options = this.getOptions(fields);
    });
  }

  getOptions(fields: Field[]) {
    // unique
    const fieldUses = uniqBy(fields.map(f => f.fieldUse!), "id")
    const areas = fieldUses.map((fieldUse) => {
      const fieldsWithFieldUse = fields.filter((f) => f.fieldUse!.id === fieldUse!.id)
      const areas = fieldsWithFieldUse.map((f) => f.area!).reduce((a, b) => a + b, 0)
      return Math.round(areas / 10000 * 100) / 100;
    });

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      yAxis: { type: 'value' },
      xAxis: {
        type: 'category',
        data: fieldUses.map(f => f.name),
        show: false,
      },
      series: [
        {
          name: 'Total Area in ha',
          data: areas.map((value, index, self)=> {
            return {
              value,
              itemStyle: {
                color: RandomColor.getRandomColorBasedOnNumber(parseInt(fieldUses[index].snarId)),
              }
            }
          }),
          type: 'bar',
        }
      ]
    };
  }
}
