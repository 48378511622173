import { Component } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { FieldsService } from 'src/app/services/fields.service';
import { AreaRange, FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent {
  sliderMin: number = 0;
  sliderMax: number = 10000;

  sliderLeftValue: number = 0;
  sliderRightValue: number = 10000;
  sliderFormatLabel(value: number): string {
    if (value >= 10000) {
      return Math.round(value / 10000) + 'ha';
    } else {
      return Math.round(value) + 'm²';
    }
  }

  constructor(private fs: FieldsService, public fil: FilterService) {
    combineLatest([this.fs.areas$.pipe(map<number[], AreaRange>((areas) => {

      const min = Math.min(...areas, 0);
      const max = Math.max(...areas, 0);
      return {min, max};
    })), this.fil.areaRange$]).subscribe(([total, filter])=> {
      this.sliderMin = total.min!;
      this.sliderMax = total.max!;

      this.sliderLeftValue = Math.max(filter.min ?? this.sliderMin, this.sliderMin);
      this.sliderRightValue = Math.min(filter.max ?? this.sliderMax, this.sliderMax);
    });
  }
}
