import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchLocation = new Subject<google.maps.LatLng>();
  public searchLocation$: Observable<google.maps.LatLng> = this.searchLocation.asObservable();

  constructor() { }

  public setLocation(location: google.maps.LatLng) {
    this.searchLocation.next(location);
  }
}
