import { Component } from '@angular/core';
import { FieldUseService } from 'src/app/services/field-use.service';
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';

@Component({
  selector: 'app-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss']
})
export class InfoSectionComponent {


  area: string = 'all';
  constructor(public fs: FilteredFieldsService, public fus: FieldUseService) {
    this.fs.filteredAreas$.subscribe(areas => {
      const areaSummed = areas.reduce((acc, area) => {
        return acc + area;
      }, 0);

      if(areaSummed < 100000) {
        this.area = Math.round(areaSummed)+'m²';
      } else {
        this.area = Math.round(areaSummed/10000)+'ha';
      }
    });
  }

}
