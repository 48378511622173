import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { BehaviorSubject, firstValueFrom, map, Subject } from 'rxjs';
import { Field, FieldControllerService } from 'src/app/api';
import { FieldsService } from 'src/app/services/fields.service';
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';
import { SearchService } from 'src/app/services/search.service';
import { VisibleAreaService } from 'src/app/services/visible-area.service';
import GisUtils from 'src/app/util/gis-utils';
import GoogleMapsUtils from 'src/app/util/google-maps-utils';
import RandomColor from 'src/app/util/random_color';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  @Input("showFields") showFields: boolean = true;

  @ViewChild(GoogleMap)
  public map!: GoogleMap;

  @ViewChild(MapInfoWindow)
  public infoWindow!: MapInfoWindow;

  center?: google.maps.LatLngLiteral;

  place: google.maps.places.PlaceResult | undefined;
  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    streetViewControl: false,
    zoomControl: false,
    panControl: false,
    scaleControl: false,
    rotateControl: false,
    zoom: 15,
    minZoom: 10,    
  };

  fields: Field[] = [];




  constructor(
    public fs: FilteredFieldsService,
    private va: VisibleAreaService,
    private ss: SearchService,
    private fcs: FieldControllerService,
  ) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
    });
    this.ss.searchLocation$.subscribe((location) => {
      this.center = location.toJSON();
    });
    this.fs.filteredFields$.subscribe((fields) => {
      this.fields = fields;
    });
  }

  async boundsChanged() {
    if (this.map == null) {
      console.log("Map is null");
      return;
    }
    const bounds = this.map.getBounds()
    if (bounds == null) {
      console.log("Bounds is null");
      return;
    }
    this.va.setBounds(bounds);
  }

  fieldToVertices(field: Field) {
    return GoogleMapsUtils.fieldToVertices(field);
  }

  getPolygonOptions(field: Field): google.maps.PolygonOptions {
    const color = RandomColor.getRandomColorBasedOnNumber(parseInt(field.fieldUse?.snarId!));

    return {
      strokeColor: color,
      fillColor: color,
    }
  }



  async onPolygonClick(field: Field) {
    console.log("Clicked on polygon: ", field.id);
    this.infoWindow.position = GisUtils.getCenterOfPolygon(field.polygon.coordinates![0] as Array<number[]>);
    const area = await firstValueFrom(this.fcs.getFieldArea(field.id!));
    const fieldUse = field.fieldUse!.name;

    this.infoWindow.infoWindow?.setContent(`<p id="infowindow-content">Area: ${area.toFixed(2)}m²<br>Field use: ${fieldUse}</p>`);
    this.infoWindow.open();

  }
}
