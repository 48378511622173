<mat-card class="filter-card">
  <mat-card-header>
    <mat-card-title style="color: white; display: flex; align-items: center;">
      <mat-icon>search</mat-icon>
      Filter options
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div style="display: flex; flex-direction: column;margin-top: 20px">
      <span>Filter by area size</span>
      <div style="display: flex; flex-direction: row; align-items: center;">
        <span>{{sliderFormatLabel(sliderMin)}}</span>
        <div style="flex-grow: 1;">
          <mat-slider fullWidth [min]="sliderMin" [max]="sliderMax" step="100" discrete [displayWith]="sliderFormatLabel">
            <input [value]="sliderLeftValue" (valueChange)="fil.setMinArea($event)" matSliderStartThumb>
            <input [value]="sliderRightValue" (valueChange)="fil.setMaxArea($event)" matSliderEndThumb>
          </mat-slider>
        </div>
        <span>{{sliderFormatLabel(sliderMax)}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
