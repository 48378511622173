import { Injectable } from '@angular/core';
import { combineLatest, map, ReplaySubject } from 'rxjs';
import { Field } from '../api';
import { FieldsService } from './fields.service';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class FilteredFieldsService {
  private filteredFields = new ReplaySubject<Field[]>();
  filteredFields$ = this.filteredFields.asObservable();
  filteredAreas$ = this.filteredFields$.pipe(map((fields) => fields.map((field) => field.area!)));

  constructor(private fil: FilterService, private fs: FieldsService) {

    combineLatest([fs.fields$, this.fil.enabledFieldUses$, this.fil.areaRange$]).subscribe(([fields, enabledFieldUses, areaRange]) => {
      const filteredFields = fields.filter((field) => {
        return enabledFieldUses.find((f) => f[0].id == field.fieldUse!.id)![1];
      });
      const areaFilteredFields = filteredFields.filter((field) => {
        return (areaRange.min ?? 0) <= field.area! && field.area! <= (areaRange.max ?? Infinity);
      });
      this.filteredFields.next(areaFilteredFields);
    });
  }
}
