import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showFields: boolean = true;
  showFilters: boolean = true;

  toggleShowFields() {
    this.showFields = !this.showFields;
  }

  toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

}
