import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { Field, FieldControllerService } from '../api';
import { FilterService } from './filter.service';
import { VisibleAreaService } from './visible-area.service';

@Injectable({
  providedIn: 'root'
})
export class FieldsService {
  private fields = new ReplaySubject<Field[]>();
  private areas = new ReplaySubject<number[]>();
  fields$ = this.fields.asObservable();
  areas$ = this.areas.asObservable();

  constructor(private va: VisibleAreaService, private fc: FieldControllerService) {
    this.va.boundingBox$.subscribe(async (bounds) => {
      const fieldsPromise = firstValueFrom(this.fc.findAllWithinBounds(bounds));
      const areasPromise = firstValueFrom(this.fc.getAreasWithinBounds(bounds));

      const fields = await fieldsPromise;
      const areas = await areasPromise;
      console.log("Fields: ", fields.length);
      this.fields.next(fields);
      this.areas.next(areas);
    });
  }
}
