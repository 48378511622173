import { Component } from '@angular/core';
import { uniq, uniqBy } from 'lodash';
import { Field } from 'src/app/api';
import { FilteredFieldsService } from 'src/app/services/filtered-fields.service';
import RandomColor from 'src/app/util/random_color';

@Component({
  selector: 'app-area-to-count-chart',
  templateUrl: './area-to-count-chart.component.html',
  styleUrls: ['./area-to-count-chart.component.scss']
})
export class AreaToCountChartComponent {
  options: any;

  constructor(private fs: FilteredFieldsService) {
    this.fs.filteredFields$.subscribe((fields) => {
      this.options = this.getOptions(fields);
    });
  }

  getOptions(fields: Field[]) {
    const fieldUses = uniqBy(fields.map((f) => f.fieldUse!), "id")
    const fieldUseNames = fieldUses.map((f) => f.name)
    const areasByFieldUse = fieldUses.map((fieldUse) => {
      const fieldsWithFieldUse = fields.filter((f) => f.fieldUse!.id === fieldUse!.id)
      const areas = fieldsWithFieldUse.map((f) => f.area!)
      const squareMeters =  areas.reduce((a, b) => a + b, 0)
      const hectares = Math.round((squareMeters / 10000)*100)/100

      return hectares
    })
    const countsByFieldUse = fieldUses.map((fieldUse) => {
      const fieldsWithFieldUse = fields.filter((f) => f.fieldUse!.id === fieldUse.id)
      return fieldsWithFieldUse.length
    })

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      xAxis: {
        type: 'category',
        data: fieldUseNames,
        show: false
      },
      series: [
        {
          name: 'Total Area in ha',
          type: 'bar',
          data: areasByFieldUse.map((area, index, self)=>{
            return {
              value: area,
              itemStyle: {
                color: RandomColor.getRandomColorBasedOnNumber(parseInt(fieldUses[index].snarId))
              }
            }
          })
        },
        {
          name: 'Count',
          type: 'bar',
          data: countsByFieldUse
        }
      ]
    }
  }
}
